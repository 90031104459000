@import '~material-symbols';
@tailwind base;
@tailwind components;
@tailwind utilities;

.material-symbols-outlined {
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}

.material-symbols-sharp {
  font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
}

html,
body {
    height: 100% !important;
    font-size: 10px !important;
}

body {
    margin: 0;
    font-family: 'BoeingMeso', 'sans-serif';
    color: #5e5e5e;
    background-color: #ececec;
    overflow: visible !important;
}

.content-loaded {
    display: none !important;
}

.content-loading {
    position: fixed !important;
    z-index: 999 !important;
    overflow: show !important;
    margin: auto !important;
    top: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    width: 50px !important;
    height: 50px !important;
    min-height: 500px !important;
}

.content-loading:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
}


/* :not(:required) hides these rules from IE9 and below */

.content-loading:not(:required) {
    /* hide "content-content-loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.content-loading:not(:required):after {
    content: "";
    display: block;
}

.w-6\/10 {
    width: 60% !important;
}

button:focus {
    outline: none !important;
}

.pb-px-24 {
    padding-bottom: 24px !important;
}

.h-px-32 {
    height: 32px !important;
}

.text-px-16 {
    font-size: 16px !important;
}

.text-px-10 {
    font-size: 10px !important
}

.text-px-12 {
    font-size: 12px !important;
}

.text-px-13 {
    font-size: 13px !important;
}

.text-px-15 {
    font-size: 15px !important;
}

.text-px-14 {
    font-size: 14px !important;
}

.text-px-18 {
    font-size: 18px !important;
}

.text-px-20 {
    font-size: 20px !important;
}

.text-px-24 {
    font-size: 24px !important;
}

.text-px-30 {
    font-size: 30px !important;
}

.text-px-60 {
    font-size: 60px !important;
}

.dialog-width {
    min-width: 400px !important;
}

@media screen and (min-width: 1024px) {
    .user-role-container-dialog {
        width: 60vw;
    }
}

.user-eol-container-dialog {
    width: auto !important;
    height: 90% !important;
}

.user-eol-container-dialog>.mat-dialog-container {
    display: block;
    padding: 24px;
    border-radius: 4px;
    box-sizing: border-box;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    -ms-overflow-y: auto !important;
    width: 100%;
    height: 95%;
    min-height: inherit;
    max-height: inherit;
}

.user-eol-container-dialog-backdrop-background {
    background: url("assets/img/browser-eol/browser-eol-image.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}

@media (min-width: 350px) and (max-width: 600px) {
    .user-eol-container-dialog {
        width: 90% !important;
        height: 90% !important;
    }
    .user-eol-container-dialog>.mat-dialog-container {
        display: block;
        padding: 24px;
        border-radius: 4px;
        box-sizing: border-box;
        overflow-y: auto !important;
        overflow-x: hidden !important;
        -ms-overflow-y: auto !important;
        width: 100%;
        height: 500px !important;
        min-height: inherit;
        max-height: inherit;
    }
}

@media (min-width: 601px) and (max-width: 1080px) {
    .user-eol-container-dialog {
        width: 90% !important;
        height: 100% !important;
    }
    .user-eol-container-dialog>.mat-dialog-container {
        display: block;
        padding: 24px;
        border-radius: 4px;
        box-sizing: border-box;
        overflow-y: auto !important;
        overflow-x: hidden !important;
        -ms-overflow-y: scroll !important;
        width: 100%;
        height: 640px !important;
        min-height: inherit;
        max-height: inherit;
    }
}

.mat-dialog-container {
    padding-bottom: 2.4rem !important;
    padding-right: 2.4rem !important;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
    background-color: #009cde !important;
}

.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused,
.mat-step-header:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

// Multi select dropdown styles start
.cuppa-dropdown {
    position: static !important;
}

.dropdown-list {
    width: auto !important;
    bottom: auto !important;
    z-index: 999 !important;
}

.lazyContainer {
    height: auto !important;
}

.selected-list .c-btn {
    background-color: rgba(0, 0, 0, 0.04) !important;
    height: 45px !important;
}

.arrow-down {
    transform: rotate(0deg) !important;
    top: 0 !important;
}

.selected-list .c-list {
    width: calc(100% - 30px) !important;
}

span.c-remove.clear-all {
    display: none !important;
}

.c-btn {
    font-size: 13px !important;
}

// Multi select dropdown styles end
// Mat snack bar custom position
.notification-custom-snackbar {
    margin: 0 !important;
    position: absolute !important;
    right: 2.5rem !important;
    top: 14rem !important;
}

.unplanned-snackbar-color-band {
    border-left-width: 0.8rem !important;
    // border-left-color: #e4002b !important;
    border-left-color: #D32F2F !important;
}

.planned-snackbar-color-band {
    border-left-width: 0.8rem !important;
    // border-left-color: #ffa300 !important;
    border-left-color: #ED6C02 !important;
}

.information-snackbar-color-band {
    border-left-width: 0.8rem !important;
    // border-left-color: #009cde !important;
    border-left-color: #1976D2 !important;
}

.training-snackbar-color-band {
    border-left-width: 0.8rem !important;
    border-left-color: #2E7D32 !important;
}

.action-req-snackbar-color-band {
    border-left-width: 0.8rem !important;
    border-left-color: #7B1FA2 !important;
}

.fix-mat-form-field .mat-form-field .mat-form-field-wrapper {
    padding-bottom: 0;
}

.fix-mat-form-field .mat-form-field .mat-form-field-wrapper .mat-form-field-underline {
    position: static !important;
    display: block;
    margin-top: -1px;
}

.fix-mat-form-field .mat-form-field .mat-form-field-wrapper .mat-form-field-subscript-wrapper,
.fix-mat-form-field .mat-form-field .mat-form-field-wrapper .mat-form-field-ripple {
    position: static !important;
    display: table;
}

.fix-mat-form-field .mat-form-field .mat-form-field-wrapper .mat-form-field-subscript-wrapper {
    min-height: calc(1em + 1px);
}

textarea.textarea_resize.mat-input-element.cdk-textarea-autosize {
    box-sizing: content-box;
}

// Style Mat snack bar Button
.notification-custom-snackbar .mat-button-wrapper {
    letter-spacing: 1px !important;
    line-height: 16px !important;
    font-weight: 600 !important;
}

// Your Accoount feature customization - start
.inline-block-preferences .mat-form-field {
    font-family: 'BoeingMeso', 'sans-serif' !important;
    line-height: normal !important;
    letter-spacing: 0.5px !important;
    width: 70% !important;
}

.inline-block-preferences .mat-select-value {
    color: black;
    font-family: 'BoeingMeso', 'sans-serif';
    font-weight: 400;
    font-size: 16px !important;
}

.inline-block-preferences .mat-option {
    font-family: 'BoeingMeso', 'sans-serif' !important;
}

.inline-block-preferences .mat-checkbox-layout {
    white-space: normal !important;
    font-family: 'BoeingMeso', 'sans-serif' !important;
    font-size: 12px !important;
    color: rgba(0, 0, 0, 0.6) !important;
    width: 100% !important;
    min-height: 4.5rem !important;
}

.inline-block-preferences .mat-checkbox-layout .mat-checkbox-label {
    line-height: 16px !important;
    width: 90% !important;
    letter-spacing: normal !important;
}

// Your Accoount feature customization - end
.backToTopBtn.mat-raised-button {
    background-color: #ffffff !important;
    border-radius: 0.2rem !important;
    box-shadow: 0.1rem -0.1rem 1.5rem 0.1rem #b3b3b3 !important;
    color: #000000 !important;
    font-family: 'BoeingMeso', 'sans-serif' !important;
    font-size: 1.5rem !important;
    line-height: 1 !important;
    padding: 1.5rem !important;
    padding-left: 1rem !important;
}

.list-mat-menu.mat-menu-panel {
    margin-left: -19px !important;
    max-width: 263px !important;
    margin-top: 15px !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
    padding: 2px 0 !important;
    box-sizing: content-box !important;
    height: 0 !important;
}

.folderNames.mat-menu-panel {
    max-height: 256px !important;
    max-width: 250px !important;
}

.row-height-scroll .mat-list-base .mat-list-item .mat-list-item-content {
    padding-left: 1.6rem;
    padding-right: 0rem;
}

.tooltip {
    border-radius: 2px !important;
    background-color: #737373 !important;
    color: #ffffff !important;
    font-family: 'BoeingMeso', 'sans-serif' !important;
    font-size: 13px !important;
    padding: 5px 10px !important;
    text-align: center !important;
}
::ng-deep .tooltip-pd {
    padding: 16px 16px 16px 16px;
    color:#FFFFFF !important;
    margin-bottom: 5px;
    text-align: center;
    white-space: pre-line;
    width: 200px;
    height: auto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    font-family: 'BoeingMeso', 'sans-serif' !important;
    background: #424242;
    border-radius: 25px;
    overflow: visible !important;
    left: -200px !important;
    bottom: -10px !important;
    border: 2px solid #666;
  }
  .icon-style {
    font-size: 20x !important;
    padding-left: 1px;
    padding-right: 1px;
    padding-top: 3px;
    color: #416c87;
  }
  .add-link-style {
    font-variation-settings: "FILL" 1, "wght" 600, "GRAD" 0, "opsz" 24 !important;
}

.text-px-11 {
    font-size: 11px !important;
}

.text-px-17 {
    font-size: 17px !important;
}

// Snackbar notification width for smaller screens
@media (max-width: 600px) {
    .mat-snack-bar-container {
        max-width: 76vw !important;
        right: 12vw !important;
    }
}

.mat-drawer-container {
    background-color: #f5f5f5 !important;
}

.mat-drawer-backdrop.mat-drawer-shown {
    background-color: rgba(37, 55, 70, 0.12) !important;
}

.account-menu.mat-menu-panel {
    margin-top: 11px !important;
    margin-left: -23.1rem !important;
    width: 300px !important;
    max-width: 300px !important;
    height: 159px;
}

.link-menu.mat-menu-panel {
    margin-left: -16.5rem !important;
    width: 298px !important;
    margin-top: 11px !important;
    max-width: 298px !important;
}

.bgm-white {
    background-color: #ffffff !important;
}

.bgm-White {
    background-color: #FFFFFF !important;
}

.c-white {
    color: #ffffff !important;
}

.bgm-black {
    background-color: #000000 !important;
}

.c-black {
    color: #000000 !important;
}

.bgm-umber {
    background-color: #412020 !important;
}

.c-umber {
    color: #412020 !important;
}

.bgm-magenta {
    background-color: #ec008c !important;
}

.c-magenta {
    color: #ec008c !important;
}

.bgm-red {
    background-color: #eb0029 !important;
}

.c-red {
    color: #eb0029 !important;
}

.bgm-lightpurple {
    background-color: #7e3da2 !important;
}

.c-lightpurple {
    color: #7e3da2 !important;
}

.bgm-purple {
    background-color: #5e0d8b !important;
}

.c-purple {
    color: #5e0d8b !important;
}

.bgm-lightcyan {
    background-color: #33afe5 !important;
}

.c-lightcyan {
    color: #33afe5 !important;
}

.bgm-cyan {
    background-color: #009bde !important;
}

.c-cyan {
    color: #009bde !important;
}

.bgm-teal {
    background-color: #008aab !important;
}

.c-teal {
    color: #008aab !important;
}

.bgm-viridian {
    background-color: #007166 !important;
}

.c-viridian {
    color: #007166 !important;
}

.bgm-green {
    background-color: #82bc00 !important;
}

.c-green {
    color: #82bc00 !important;
}

.bgm-lightgreen {
    background-color: #9bc933 !important;
}

.c-lightgreen {
    color: #9bc933 !important;
}

.bgm-yellow {
    background-color: #ffdb00 !important;
}

.c-yellow {
    color: #ffdb00 !important;
}

.bgm-amber {
    background-color: #ffa400 !important;
}

.c-amber {
    color: #ffa400 !important;
}

.bgm-lightamber {
    background-color: #ffb633 !important;
}

.c-lightamber {
    color: #ffb633 !important;
}

.bgm-orange {
    background-color: #e24301 !important;
}

.c-orange {
    color: #e24301 !important;
}

.bgm-gray {
    background-color: #9e9e9e !important;
}

.c-gray {
    color: #9e9e9e !important;
}

.bgm-steel {
    background-color: #263746 !important;
}

.c-steel {
    color: #263746 !important;
}

.bgm-rose {
    background-color: #ad293f !important;
}

.c-rose {
    color: #ad293f !important;
}

.bgm-forest {
    background-color: #1a6500 !important;
}

.c-forest {
    color: #1a6500 !important;
}

.bgm-strongorange {
    background-color: #cb3e00 !important;
}

.c-strongorange {
    color: #cb3e00 !important;
}

.bgm-strongred {
    background-color: #c90015 !important;
}

.c-strongred {
    color: #c90015 !important;
}

.bgm-burgundy800 {
    background-color: #ad293f !important;
}

.c-burgundy800 {
    color: #ad293f !important;
}

.bgm-charcoal700 {
    background-color: #605e5c !important;
}

.c-charcoal700 {
    color: #605e5c !important;
}

.bgm-shark800 {
    background-color: #454c4e !important;
}

.c-shark800 {
    color: #454c4e !important;
}

.bgm-jade800 {
    background-color: #016358 !important;
}

.c-jade800 {
    color: #016358 !important;
}

.bgm-green800 {
    background-color: #488700 !important;
}

.c-green800 {
    color: #488700 !important;
}

.bgm-steel800 {
    background-color: #374c58 !important;
}

.c-steel800 {
    color: #374c58 !important;
}

.bgm-chestnut800 {
    background-color: #4f2d2c !important;
}

.c-chestnut800 {
    color: #4f2d2c !important;
}

.bgm-green900 {
    background-color: #1a6500 !important;
}

.c-green900 {
    color: #1a6500 !important;
}

.bgm-cyan900 {
    background-color: #005895 !important;
}

.c-cyan900 {
    color: #005895 !important;
}

.bgm-platinum800 {
    background-color: #323436 !important;
}

.c-platinum800 {
    color: #323436 !important;
}

.bgm-evergreen800 {
    background-color: #34634b !important;
}

.c-evergreen800 {
    color: #34634b !important;
}

.bgm-magenta800 {
    background-color: #ac076d !important;
}

.c-magenta800 {
    color: #ac076d !important;
}

.bgm-navy800 {
    background-color: #103358 !important;
}

.c-navy800 {
    color: #103358 !important;
}

.bgm-purple800 {
    background-color: #7b0f99 !important;
}

.c-purple800 {
    color: #7b0f99 !important;
}

.bgm-lime800 {
    background-color: #90a032 !important;
}

.c-lime800 {
    color: #90a032 !important;
}

.bgm-orange900 {
    background-color: #c43600 !important;
}

.c-orange900 {
    color: #c43600 !important;
}

.bgm-teal800 {
    background-color: #007a91 !important;
}

.c-teal800 {
    color: #007a91 !important;
}

.bgm-peacockblue700 {
    background-color: #256476 !important;
}

.c-peacockblue700 {
    color: #256476 !important;
}

.bgm-peacockblue {
    background-color: #256476 !important;
}

.c-peacockblue {
    color: #256476 !important;
}

.c-blue {
    color: blue !important;
}

.bgm-sky900 {
    background-color: #004b7a !important;
}

.c-sky900 {
    color: #004b7a !important;
}

.bgm-steel800 {
    background-color: #374c5e !important;
}

.c-steel800 {
    color: #374c5e !important;
}

.bgm-teal800 {
    background-color: #007a91 !important;
}

.c-teal800 {
    color: #007a91 !important;
}

.bgm-green500 {
    background-color: #84bd00 !important;
}

.c-green500 {
    color: #84bd00 !important;
}

.bgm-slate50 {
    background-color: #eeeeee !important;
}

.c-slate50 {
    color: #eeeeee !important;
}

.bgm-coolgray200 {
    background-color: #d9d9d6 !important;
}

.c-coolgray200 {
    color: #d9d9d6 !important;
}

.bgm-coolgray400 {
    background-color: #b5b5b2 !important;
}

.c-coolgray400 {
    color: #b5b5b2 !important;
}

.bgm-cyan900 {
    background-color: #005895 !important;
}

.c-cyan900 {
    color: #005895 !important;
}

.bgm-red900 {
    background-color: #c90015 !important;
}

.c-red900 {
    color: #c90015 !important;
}

.get-cursor {
    cursor: pointer !important;
}

.bgm-inactive {
    background-color: #dbdce0 !important;
}

.inactiveStyles {
    cursor: not-allowed !important;
}

.inactive-icon {
    color: rgba(0, 0, 0, 0.38) !important;
    cursor: not-allowed !important;
}

.bgm-inactive {
    background-color: #dbdce0 !important;
}

.disabled-widget {
    color: rgba(0, 0, 0, 0.38) !important;
}

.inactive {
    cursor: not-allowed !important;
}

// Next Gen color codes - Start
// APIs
.bg-burgundy800{
    background: var(--Gradient---Red, linear-gradient(114deg, #DF256F 0%, #940C2F 100%));
}

// Boeing Internal
.bg-shark800{
    background: var(--Gradient---Shark, linear-gradient(114deg, #8A9497 0%, #454B4E 100%));
}


// Collaboration Tools
.bg-jade800{
    background: var(--Gradient---Jade, linear-gradient(114deg, #0E9284 0%, #00473F 100%));
}

// Community
.bg-green800{
    background: var(--Gradient---Spring, linear-gradient(114deg, #8DC74B 0%, #488700 100%));
}

// Dashboard
.bg-steel800{
    background: var(--Gradient---Steel, linear-gradient(114deg, #5E788E 0%, #1D3141 100%));
}

// Disaster Recovery
.bg-strongred{
    background: var(--Gradient---Strong-Red, linear-gradient(114deg, #FF6E7D 0%, #C90015 100%));
}


// Finance
.bg-orange900{
    background: var(--Gradient---Orange, linear-gradient(114deg, #FF7200 0%, #C43600 100%));
}

// Fleet Management
.bg-cyan{
    background: var(--Gradient---Dark-Gray, linear-gradient(114deg, #74797D 0%, #282A2C 100%));
}

// Flight Operations
.bg-evergreen800{
    background: var(--Gradient---Green, linear-gradient(114deg, #72B094 0%, #34634B 100%));
}

// Leasing
.bg-peacockblue{
    background: var(--Gradient---Peacock, linear-gradient(114deg, #4A97AD 0%, #256476 100%));
}

// Legal / Regulatory
.bg-magenta800{
    background: var(--Gradient---Magenta, linear-gradient(126deg, #D452A3 0%, #9E0062 100%));
}

// Maintenance and Engineering
.bg-navy800{
    background: var(--Gradient---Dark-Blue, linear-gradient(114deg, #3073A6 0%, #0B2B4D 100%));
}

// Material Management
.bg-purple800{
    background: var(--Gradient---Purple, linear-gradient(126deg, #BB39D0 0%, #520C65 100%));
}

// Mobile Apps
.bg-charcoal700{
    background: var(--Gradient---Charcoal, linear-gradient(114deg, #B9B9B9 0%, #605E5C 100%));
}

// Other
.bg-shark800{
    background: var(--Gradient---Shark, linear-gradient(114deg, #8A9497 0%, #454B4E 100%));
}

// Technical Support
.bg-lime800{
    background: var(--Gradient---Green, linear-gradient(114deg, #C3CE61 0%, #83922C 100%));
}

// Training
.bg-green900{
    background: var(--Gradient---Forest, linear-gradient(114deg, #2B9C00 0%, #155100 100%));
}

// Utilities
.bg-teal{
    background: var(--Gradient---Teal, linear-gradient(114deg, #00B3C6 0%, #006C81 100%));
}

//Test Category
.bg-test800 {
  background: linear-gradient(114deg, #EA514B 0%, #842050 100%);
  }

.bg-std-gray {
    background: var(--Gradient---Std-Gray, linear-gradient(115deg, #E7E7E7 0%, #BFBFBF 100%));
}

.bg-boeingblue {
    background-color: #0033A1 !important;
}

.bg-mediumgray {
    background-color: #5B6770 !important;
}
// Next Gen color codes - End

::ng-deep .gridster-item {
    margin: 0;
    position: absolute;
    top: 0;
    left: 10px;
    right: 10px;
    bottom: 0;
    width: auto;
    z-index: 0 !important;
    overflow-x: hidden;
    font-family: 'BoeingMeso', 'sans-serif' !important;
    font-size: 8px;
    line-height: 1.5em;
    justify-content: center !important;
    justify-items: center !important;
    display: flex;
    flex-direction: column;
    align-content: center !important;
}

.faq-cat-accordion.mat-expansion-panel-header {
    background-color: #f3f3f3 !important;
    min-height: 52px !important;
}

.faq-cat-accordion.mat-expansion-panel-header.mat-expanded {
    background-color: #f3f3f3 !important;
    min-height: 52px !important;
}

.search-faq.mat-form-field-outline-start,
.search-faq.mat-form-field-outline-end {
    border-radius: 9px !important;
}

.accordion-widget .mat-expansion-indicator::after {
    border-style: outset !important;
}

.faq-accordion-small .mat-expansion-indicator::after,
.mat-header-question .mat-expansion-indicator::after {
    border-style: outset !important;
}

.mat-expand-cat.mat-expansion-panel-spacing {
    margin: 0;
}

.faq-answer-accordion {
    a {
        color: #009bde !important;
    }
}

.mat-header-auto.mat-expansion-panel-header {
    height: auto !important;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
    min-height: 52px;
}

.search-display {
    .mat-form-field {
        width: auto !important;
        height: 45px !important;
    }
    .input {
        max-width: 100%;
        width: auto;
    }
}

@media (min-width: 992px) {
    .alt {
        border: 1px solid #9E9E9E;
        border-radius: 4px;
        background: #FFFF;
        padding: 0px;
        .mat-form-field {
            width: auto !important;
            height: 40px !important;
        }
    }
}

.alt {
    .mat-select-value {
        max-width: 100%;
        width: auto;
        text-transform: uppercase;
    }
    .mat-form-field-appearance-legacy .mat-form-field-infix {
        padding: 0;
    }
    .mat-form-field-infix {
        width: auto;
    }
}

@media (max-width: 991px) {
    .alt {
        border: 1px solid #9E9E9E;
        border-radius: 4px;
        background: #FFFF;
        padding: 0px;
        .mat-form-field {
            width: auto !important;
            cursor: pointer !important;
            height: 40px !important;
        }
    }
}

// Change the placeholder color in the search bar
.search-bar {
    background: white;
    gap: 8px;
    input::-webkit-input-placeholder {
        font-family: 'BoeingMeso', 'sans-serif';
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        color: #ADADAD !important;
    }
    input:focus::-webkit-input-placeholder {
        font-family: 'BoeingMeso', 'sans-serif';
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        color: #ADADAD !important;
    }
    input::-moz-placeholder {
        /* FF 19+ */
        font-family: 'BoeingMeso', 'sans-serif';
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        color: #ADADAD !important;
    }
    input:focus::-moz-placeholder {
        font-family: 'BoeingMeso', 'sans-serif';
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        color: #ADADAD !important;
    }
     :-ms-input-placeholder {
        /* IE 10+ */
        color: white !important;
    }
}

.search-bar-focus {
    background: white;
    color: black !important;
    input::-webkit-input-placeholder {
        font-family: 'BoeingMeso', 'sans-serif';
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        font-style: normal;
        letter-spacing: 0em;
        text-align: left;
        color: #ADADAD !important;
    }
    input::-moz-placeholder {
        /* FF 19+ */
        font-family: 'BoeingMeso', 'sans-serif';
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;

        letter-spacing: 0em;
        text-align: left;
        color: #ADADAD !important;
    }
     :-ms-input-placeholder {
        /* IE 10+ */
        color: white !important;
    }
}

@media (min-width: 992px) {
    .cross-bar {
        height: 8.5rem;
    }
}

@media (max-width: 991px) {
    .cross-bar {
        height: 7.4rem;
    }
}

.search-small-ph {
    input::-moz-placeholder {
        /* FF 19+ */
        color: #5e5e5e !important;
    }
    input::-webkit-input-placeholder {
        color: #5e5e5e !important;
    }
     :-ms-input-placeholder {
        /* IE 10+ */
        color: #5e5e5e !important;
    }
}

.icecube-menu-mr {
    margin-right: -7px !important;
}

.account-menu-mr {
    margin-right: 18px !important;
}

.help-menu-mr {
    margin-right: -6.5px !important;
    padding-bottom: 0px !important;
}

.sbiCls .mat-form-field .mat-form-field-subscript-wrapper {
    position: static;
    margin-bottom: 0.2rem;
}


/* KPI List widget feature customization - start */

.kpiListTabGroup .mat-tab-label {
    height: 6.4rem !important;
    min-width: 33.3%;
}

.kpiListTabGroupcols4 .mat-tab-label {
    height: 6.4rem !important;
    min-width: 33.3%;
    padding-left: 7% !important;
    padding-right: 14% !important;
}

.kpiListTabGroupcols6 .mat-tab-label {
    height: 6.4rem !important;
    min-width: 33.3%;
    padding-left: 7% !important;
    padding-right: 16% !important;
}

.viewOrderDialog .mat-form-field {
    font-family: 'BoeingMeso', 'sans-serif' !important;
    line-height: normal !important;
    letter-spacing: 0.05rem !important;
}

.viewOrderDialog .mat-select-value {
    color: black;
    font-family: 'BoeingMeso', 'sans-serif';
    font-weight: 400;
    font-size: 1.6rem !important;
}

.viewOrderDialog .mat-option {
    font-family: 'BoeingMeso', 'sans-serif' !important;
}

.kpiListTabGroup .mat-ink-bar {
    background-color: #009cde !important;
}

.kpiListTabGroupcols4 .mat-ink-bar {
    background-color: #009cde !important;
}

.kpiListTabGroupcols6 .mat-ink-bar {
    background-color: #009cde !important;
}

.kpiListDD .mat-form-field {
    line-height: normal !important;
    letter-spacing: 0.05rem !important;
    width: 100% !important;
}


/* KPI List widget feature customization - end */


/* MBD change company feature customization - start */

.mat-autocomplete-panel {
    overflow: hidden !important;
}

.mat-autocomplete-panel:hover {
    overflow: auto !important;
}

@media (max-width: 991px) {
    .mat-autocomplete-panel {
        overflow: auto !important;
    }
}


/* MBD change company feature customization - end */

.alt-sm {
    .mat-form-field-appearance-legacy .mat-form-field-wrapper {
        padding-bottom: 0 !important;
    }
}

.mat-tooltip {
    background-color: #212121;
    color: rgba(255, 255, 255, 0.87);
    font-family: 'BoeingMeso', 'sans-serif';
    font-size: 1.2rem;
    text-align: center;
    letter-spacing: 0;
    line-height: 1.6rem;
}

.mat-form-field.mat-focused .mat-form-field-label {
    color: #009cde !important;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: #009cde !important;
}

.mat-form-field .mat-form-field-ripple {
    background-color: #009cde !important;
}

.gridster-item-overflow {
    overflow: visible !important;
}

.delete-icon {
    position: absolute;
    z-index: 10;
    top: -0.7rem;
    left: -0.5rem;
    box-sizing: border-box;
    height: 24px;
    width: 24px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    box-shadow: 0 0 4px 0 rgb(37 55 70 / 12%);
    border-radius: 12px;
}

.close-icon {
    position: absolute;
    left: 0.55rem;
    top: 0.25rem;
}

.pointerClassNone {
    pointer-events: none !important;
}

.noPointer {
    cursor: default !important;
}

.edit-icon {
    position: absolute;
    left: 0.55rem;
    top: 0.35rem;
}

.edit-button {
    position: absolute;
    z-index: 10;
    bottom: -1.2rem;
    left: calc(100% / 2 - 11px);
    box-shadow: 0 0 4px 0 rgb(37 55 70 / 12%);
    height: 24px;
    width: 24px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #009cde;
    border-radius: 12px;
}

.edit-button-1X3 {
    position: absolute;
    z-index: 10;
    bottom: -1.2rem;
    left: calc(100% / 2 - 11px);
    box-sizing: border-box;
    box-shadow: 0 0 4px 0 rgb(37 55 70 / 12%);
    height: 24px;
    width: 24px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #009cde;
    border-radius: 12px;
}

.edit-button-3X3 {
    position: absolute;
    z-index: 10;
    top: 8rem;
    left: calc(100% / 2 - 11px);
    box-sizing: border-box;
    box-shadow: 0 0 4px 0 rgb(37 55 70 / 12%);
    height: 24px;
    width: 24px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #009cde;
    border-radius: 12px;
}

.pointerClass {
    cursor: pointer !important;
    transform: none !important;
}

gridster-item {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
}

.background-inactive-edit {
    opacity: 0.38 !important;
    background-color: #ffffff !important;
}

.QSIEmbeddedTarget img:focus {
    outline: none !important;
}

.optanon-alert-box-body .banner-policy-link {
    color: #0033a1 !important;
}

.optanon-alert-box-body .banner-policy-link:focus,
.banner-policy-link:hover {
    outline: none;
    color: #006692;
}

.no-elevation .mat-elevation-z4 {
    box-shadow: none !important;
}

.opacity-60 {
    opacity: 0.6 !important;
}

.opacity-87 {
    opacity: 0.87 !important;
}

.opacity-100 {
    opacity: 100% !important;
}

.widget-3X3-header-height {
    height: 9.2rem !important;
}

.font-BoeingMeso-reg {
    font-family: 'BoeingMeso', 'sans-serif' !important;
}

#tutorial-highlight {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    z-index: 1000;
}

#tutorial-highlight-target {
    position: absolute;
    left: 50px;
    top: 50px;
    width: 90px;
    height: 90px;
    box-shadow: 0 0 0 99999px rgba(38, 55, 70, 0.85);
}

#tutorial-highlight-glow {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.bgm-darkblue {
    background-color: #0a2240 !important;
}


/*display the count of system message for widget and apps*/

.app-sys-msg-count {
    background-color: #e4002b;
    border-radius: 50%;
    color: #ffffff;
    font-family: 'BoeingMeso', 'sans-serif';
    font-size: 1.125rem;
    height: 1.6rem;
    right: 0.3rem;
    top: 0.3rem;
    width: 1.6rem;
}

.mrcm-dialog>.mat-dialog-container {
    padding: 0px !important;
}

.mrcm-dialog {
    padding: 0px !important;
}

.cdk-global-scrollblock {
    position: initial;
    width: initial;
    overflow: hidden;
    overflow-y: scroll !important;
}

.drag-zindex {
    z-index: 10 !important;
}

@-moz-document url-prefix() {
    .widget-badge {
        position: relative;
        top: -1px;
    }
}

#product-gridster .gridster-column {
    border-left: 1px solid #cfc9c9 !important;
    border-right: 1px solid #cfc9c9 !important;
}

#product-gridster .gridster-row {
    border-top: 1px solid #cfc9c9 !important;
    border-bottom: 1px solid #cfc9c9 !important;
}

#product-gridster1 .gridster-column {
    border-left: 1px solid #cfc9c9 !important;
    border-right: 1px solid #cfc9c9 !important;
}

#product-gridster1 .gridster-row {
    border-top: 1px solid #cfc9c9 !important;
    border-bottom: 1px solid #cfc9c9 !important;
}

#product-gridster2 .gridster-column {
    border-left: 1px solid #cfc9c9 !important;
    border-right: 1px solid #cfc9c9 !important;
}

#product-gridster2 .gridster-row {
    border-top: 1px solid #cfc9c9 !important;
    border-bottom: 1px solid #cfc9c9 !important;
}

@media screen and (min-width: 1024px) {
    .widget-edit-container-dialog {
        width: 70vw;
        height: 70vh;
    }
}

.widget-auth-actions {
    position: absolute;
    top: -3px;
    right: 0px;
}

.widget-auth-edit {
    cursor: pointer;
    font-size: medium;
}

.widget-auth-done {
    color: green;
    cursor: pointer;
    font-size: medium;
}

.widget-auth-donedisabled {
    color: #a0aec0;
    cursor: not-allowed;
    font-size: medium;
}

.widget-auth-close {
    cursor: pointer;
    font-size: medium;
}

.widget-auth-remove {
    color: #f44336;
    cursor: pointer;
    font-size: medium;
}

.widget-auth-avtar {
    color: #009cde;
    cursor: pointer;
    font-size: medium;
    position: absolute;
    top: 32px;
    left: 28px;
}

.widget-edit {
    position: relative;
    border: #009cde;
    border-style: solid;
    padding: 8px 8px 0px;
}

.widget-auth-column {
    position: relative;
}

.widget-auth-column:hover mat-icon {
    visibility: visible;
}

.news-feed-inner-content .widget-auth-column mat-icon {
    visibility: visible;
}

.widget-auth-column mat-icon {
    visibility: hidden;
}

.show-14-chars {
    overflow: hidden;
    max-width: 14ch;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.show-20-chars {
    overflow: hidden;
    max-width: 20ch;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.show-25-chars {
    overflow: hidden;
    max-width: 25ch;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.custom-modalbox-content {
    mat-dialog-container {
        height: 60em !important;
        overflow-y: hidden !important;
    }
}


/* Hot parts widget feature customization - start */

.viewOrderDialog .mat-form-field {
    font-family: 'BoeingMeso', 'sans-serif' !important;
    line-height: normal !important;
    letter-spacing: 0.05rem !important;
}

.viewOrderDialog .mat-select-value {
    color: black;
    font-family: 'BoeingMeso', 'sans-serif';
    font-weight: 400;
    font-size: 1.6rem !important;
}

.viewOrderDialog .mat-option {
    font-family: 'BoeingMeso', 'sans-serif' !important;
}

.hotPartsTabGroup .mat-ink-bar {
    background-color: #009cde !important;
}

.hotPartsTypeGroup .mat-ink-bar {
    background-color: #009cde !important;
}

.hotPartsDD .mat-form-field {
    line-height: normal !important;
    letter-spacing: 0.05rem !important;
    width: 100% !important;
}

.hot-parts-actions {
    position: absolute;
    top: 9px;
    right: 0px;
}

.hot-parts-edit-actions {
    position: absolute;
    top: 9px;
    right: 0px;
}

.hot-parts {
    position: relative !important;
}

.partsSelectionList .mat-list-option:hover .hot-parts-actions {
    visibility: visible;
}

.partsSelectionList .mat-list-option .hot-parts-actions {
    visibility: hidden;
}

.hot-parts-done {
    color: green;
    cursor: pointer;
    font-size: medium !important;
}

.hot-parts-edit {
    color: #009cde;
    cursor: pointer;
    font-size: medium !important;
}

.hot-parts-search {
    color: black;
    cursor: pointer;
    font-size: medium !important;
}

.hot-parts-close {
    cursor: pointer;
    font-size: medium !important;
}

.clsdesktopContainer {
    width: 100%;
}

@media (min-width: 620px) and (max-width: 900px) {
    .customWidth {
        margin-right: 0 !important;
        max-width: 626px !important;
    }
    .customActionButtons {
        margin-right: 17% !important;
        margin-top: 2% !important;
    }
    .mat-drawer-content {
        overflow: hidden;
    }
    .customAlignButtons {
        display: inline !important;
    }
    .customAddWidget {
        max-width: 50% !important;
    }
    .customMatButton {
        min-width: 84px !important;
    }
    .mat-calendar-header-admin .mat-calendar-controls {
        margin-right: -16% !important;
    }
    .customLayout {
        min-width: 1140px !important;
    }
    .customTab {
        width: 72% !important;
    }
    .mat-tab-labels .mat-tab-label-active.ng-star-inserted {
        width: 254px !important
    }
    #product-gridster.scrollVertical.display-grid.ng-star-inserted {
        overflow-x: scroll !important;
    }
    .mat-drawer-content {
        overflow-x: scroll !important;
    }
}

gridster-item {
    display: block !important;
}

#product-gridster .ng-star-inserted .ng-star-inserted .calendar-widget.calendar-wrapper.gridster-item-overflow {
    display: block !important;
}

.customFilterPosition {
    padding-bottom: 0 !important;
}

.customCheck .mat-checkbox-layout .mat-checkbox-label {
    font-family: 'BoeingMeso', 'sans-serif' !important;
}

.hot-parts-remove {
    color: #f44336;
    cursor: pointer;
    font-size: medium !important;
}


/* Hot parts widget feature customization - end */

.mobile-carousel-check .carousel ul.carousel-list li.carousel-slide {
    height: 400px !important;
}

.carousel-slide-overlay {
    background-color: white !important;
}

.mobile-carousel-check .carousel button.mat-icon-button {
    top: 30% !important;
}

.font-weight-normal {
    font-weight: normal !important;
}

.line-height-normal {
    line-height: normal !important;
}

.letter-spacing-1 {
    letter-spacing: 1px !important;
}

.font-weight-bold {
    font-weight: bold !important;
}

.font-boeing-meso {
    font-family: 'BoeingMeso', 'sans-serif' !important;
}

.main-div-box {
    box-shadow: 0px 2px 2px 0 rgba(0, 0, 0, 0.25) !important;
}

.w-10per {
    width: 10% !important;
}

.text-high {
    color: rgba(0, 0, 0, 0.87)!important;
}

.w-4\/5 {
    width: 80% !important;
}

.w-1\/5 {
    width: 20% !important;
}

.w-1\/4 {
    width: 25% !important;
}

.w-2\/4 {
    width: 50% !important;
}
.text-left{
    align-items: left !important;
    align-content: left !important;

}

.select-value-class{
    .mat-select-value{
        overflow: visible !important;
        text-overflow: clip !important;
        max-width: 100%;
    }
}

.select-value-class-mobile{
    .mat-select-value{
        overflow: visible !important;
        text-overflow: clip !important;
        max-width: 100%;
    }
}

.mat-menu-panel-class.mat-menu-panel{
    min-width: 317px !important;
    position: relative;
    top: 2px;
  }

.mat-menu-panel-class-mobile.mat-menu-panel {
    min-width: 204px !important;
    position: relative;
    top: 2px;
}

.affected-apps-modal .mat-dialog-container {
    border-radius: 12px !important;
}

::ng-deep .mat-tooltip {
  font-family: 'BoeingMeso', 'sans-serif';
}
